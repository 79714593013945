import { render, staticRenderFns } from "./aktualnosci.vue?vue&type=template&id=2dc47cae"
import script from "./aktualnosci.vue?vue&type=script&lang=js"
export * from "./aktualnosci.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroUnit: require('/var/www/html/bielbit/www2022/upd/components/HeroUnit.vue').default,NoConnectionToNews: require('/var/www/html/bielbit/www2022/upd/components/NoConnectionToNews.vue').default,SiteFooter: require('/var/www/html/bielbit/www2022/upd/components/SiteFooter.vue').default,StickyFooter: require('/var/www/html/bielbit/www2022/upd/components/StickyFooter.vue').default})
